import axios from "axios";

// const api_base = process.env.REACT_APP_API_URL;
const api_base = 'https://api.nursea.es/';
// const api_base = 'http://localhost:3307/';
// ADMIN:wq
// eyJraWQiOiI2TVJTeHBaYjgrbFlcL3B5d3pLTFhZSnR3alBXY0NkQzU5V01QRWQ0SldtOD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxNTFlMzU0Yy00YjUyLTQ5M2EtYjEwMy05ZTZlZDM4NzgzYjUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0yLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMl9uMmxxaVFPU2QiLCJjbGllbnRfaWQiOiJxYzlncjU5am9kbXI2NDhoc2U2ZmIyNjM1Iiwib3JpZ2luX2p0aSI6IjZlZGI2YmY4LTFiOTctNDNjNi1hMmJkLWY3ZGRlMTViMmNiNiIsImV2ZW50X2lkIjoiYjI2YzZkOGItNDQ2NC00MzRjLTk2MzEtODc1NzZhNTY0YTE1IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcwNTAwNzU5OSwiZXhwIjoxNzA1MDkzOTk5LCJpYXQiOjE3MDUwMDc1OTksImp0aSI6IjgzNDZjYmU5LTAwYzctNDVlNC1iMmQzLWJiYjBlYjM1ZDgzOSIsInVzZXJuYW1lIjoiMTUxZTM1NGMtNGI1Mi00OTNhLWIxMDMtOWU2ZWQzODc4M2I1In0.P5q31fMhTtWjh3_kDSoPfZfNsSAz13O9gSscV5ak73yDr-kpEk_hBPMnToA75m38ZgyPkdeJDcgSlNT5-cYFq4ENlArlUxPjdE6A0X2y8wbRn650uL2GLa7k2PZhF1hS2FK9LunBX5Y7El-ZwsYvXL8Hflh3LuGgHidzQYrEK9woKL4YjIPsISlvJl7_BZ1x4WuebXseN_Z6SgZmU4igl5bgL-Gb4w1QxOsKWo6no6wqHgYp5UGxdr7RbpVMUIvrTeM8WyuQlTQEwTExbzARMEYnszJzJkGiZdmEVKRMt_R03cWD5h0E244EoyrHaSfgpmHLBtedQ6ASYLBxgycZow

//ORGANIZACION
// eyJraWQiOiI2TVJTeHBaYjgrbFlcL3B5d3pLTFhZSnR3alBXY0NkQzU5V01QRWQ0SldtOD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlZTA0MWRiYi1iNTcxLTRkZTItYTFiMC02ODVkY2QzZjQ1NTIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0yLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMl9uMmxxaVFPU2QiLCJjbGllbnRfaWQiOiJxYzlncjU5am9kbXI2NDhoc2U2ZmIyNjM1Iiwib3JpZ2luX2p0aSI6ImQzNmYwNzIwLThkNDAtNDk5MC04ZTMzLWQ5ODUzYWQyNzI5NyIsImV2ZW50X2lkIjoiNzM4N2VhNzgtNmVkOC00YzM3LWJiZWUtNzhhMjEwMDY2ZDQ0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcwNTAwNjA2MCwiZXhwIjoxNzA1MDkyNDYwLCJpYXQiOjE3MDUwMDYwNjAsImp0aSI6ImNhYzhhOGI1LWM2YjUtNDA3Ny04ZTgyLTYwZGRkN2YwYTFjMiIsInVzZXJuYW1lIjoiZWUwNDFkYmItYjU3MS00ZGUyLWExYjAtNjg1ZGNkM2Y0NTUyIn0.k5pp6okGXtOVY7NvgI68P-8B_BrEAuDn5QlZ5WqvM_JhyzQzWheJ7O_0f376BXZvdbpDFNCDLiPkw6tzHW_4DlADCPVqTEbVtlebbVRxwhXfa5qUFz8USSF3RG2ZqZoSLUyDUkgopX7LwNSZLWT2NZK2hv62BCCAMm4NpsImsqqPWTY0AAhA5-o49nTggY1Hwu3mFITtxUXmW1r61NMgKoIIKSZntztOvlunn5L22_lt5r_YHNwCfp5DVs1ibu5ADh8X9W3MypWVUQhX80ZdnVl8Q3b1_O3Rp9fotxF0YxSagqDJt26_-_3Wy07eFc5hEkdvgTiTFi5kxZeOdCUtDQ

//NURSE
// eyJraWQiOiI2TVJTeHBaYjgrbFlcL3B5d3pLTFhZSnR3alBXY0NkQzU5V01QRWQ0SldtOD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkMDJiZTQyNi00OGMzLTQyOTktOTY1ZS1hZTA2ZmUzZmQ1NmYiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0yLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMl9uMmxxaVFPU2QiLCJjbGllbnRfaWQiOiJxYzlncjU5am9kbXI2NDhoc2U2ZmIyNjM1Iiwib3JpZ2luX2p0aSI6ImZiOWRjOWY0LWIwNWItNDI2OC1hYTQ2LTZiZDI3Zjk1ZjU4YiIsImV2ZW50X2lkIjoiYzUyMTBkNjgtYTQxZi00ZWI3LTgxZDItNjk0MWRlM2MxYzUyIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcwNTAwNzQ3NywiZXhwIjoxNzA1MDkzODc3LCJpYXQiOjE3MDUwMDc0NzcsImp0aSI6Ijc4YjU2M2Q5LTNiMGMtNDkzNi1iNzY3LTY5ZjhlZmExMGMwMSIsInVzZXJuYW1lIjoiZDAyYmU0MjYtNDhjMy00Mjk5LTk2NWUtYWUwNmZlM2ZkNTZmIn0.BArJ4itc6iJWw5sri6Yx54Zm2fSYL285jSfo63dRPN_5He0sYjepwwi5wf6vZwVeBW-pR-GSCdsj031QEKqgjYsjmFpXiOnmi-Fm8-DVMsi0MLk6GdAoMwkMlmWfuesErFtgvrRBYnSgTNy5wnvSLEe3eYZ92QBwgwrJBYx0z7cgoJAPJTcEE6bOztxNNr1YoNIIVcECAJDJsUTNKIbSTi1yDxN5dWjVWoh6jvBm6LakYc1o4a95THpcgSdjA8htEA2ZFMOTwoH7vbft6jbUp5hQ0HUDXI56wVeyef9Kg52J4qz98HWSG01nOATpw17uvhaMOAf4sozvZxDi-guJ6Q


const configMedia = () =>{
    let token = localStorage.getItem('token')
    return { headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+token
      }}
}

const config = () =>{
    let token = localStorage.getItem('token')
    return { headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+token
    }}
}

export const Login = ({email, password}) => {
    var url  = `${api_base}authentication/login`
    console.log(url)

    return axios.post(url,{email, password},config()).then((res)=>{
        //return response(res)
        return res
    }).catch((err)=>{
        return err
    });
  
}

export const Register = (body) => {
    var url  = `${api_base}authentication/register`

    return axios.post(
    url,
    body,
    { headers: {
        "Content-Type": "application/json",
    }}).then((res)=>{
        //return response(res)
        return res
    }).catch((err)=>{
        return err
    });
  
}

// export const Service = (method, service, body) => {
//     var url  = `${api_base}${service}`
//     switch (method) {
//         case "GET":
      
//             return axios.get(url,config()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });

//         case "POSTMEDIA":

//             return axios.post(url,body,configMedia()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });

//         case "POST":

//             return axios.post(url,body,config()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });


           
//         case "PUT":

//             return axios.put(url,body,config()).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });
           
            
//         case "DELETE":
//             let token = localStorage.getItem('token')
//             return axios.delete(url,{
//                 headers: {
//                     "Content-Type": "application/json",
//                     'Authorization': 'Bearer '+token
//                 },
//                 data:body
//             }).then((res)=>{
//                 //return response(res)
//                 return res
//             }).catch((err)=>{
//                 return err
//             });
            
          

//         default:
//             break;
//     }
// }


export const Service = (method, service, body) => {
    var url  = `${api_base}${service}`

    let token = localStorage.getItem('token')
    // let token = 'eyJraWQiOiI2TVJTeHBaYjgrbFlcL3B5d3pLTFhZSnR3alBXY0NkQzU5V01QRWQ0SldtOD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkMDJiZTQyNi00OGMzLTQyOTktOTY1ZS1hZTA2ZmUzZmQ1NmYiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0yLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMl9uMmxxaVFPU2QiLCJjbGllbnRfaWQiOiJxYzlncjU5am9kbXI2NDhoc2U2ZmIyNjM1Iiwib3JpZ2luX2p0aSI6IjQwMzY0YmI2LWJiYzMtNDk3Mi04ZTI3LWYzMzhkZWJiMWE5YyIsImV2ZW50X2lkIjoiNzgzYjAyMTAtOTlmMy00ZTBlLWEzYjItMWEwZDE3NmIzYTYyIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcwNjQyNzA4NSwiZXhwIjoxNzA2NTEzNDg1LCJpYXQiOjE3MDY0MjcwODUsImp0aSI6IjZlYjdmOTQ1LWY0MjgtNDQyYi05ZDFmLWQyNjk0YzY4MGRmYyIsInVzZXJuYW1lIjoiZDAyYmU0MjYtNDhjMy00Mjk5LTk2NWUtYWUwNmZlM2ZkNTZmIn0.0OyR3BKJwpSimh4PPbqh5Cxm004Nuvnh-lzgQ2fVfGeY9Eqo7US_78yzTngoR2Pa1_sV_dEqwe5OoEoEravL2vI9iDvqkWrZ_w9bKgarvPh0zbP5sdTEIMRCEw-4eWEwV1yT_4RGxf062NbWIHFvUyusPihw_QXXUHimB_HYcnT0g5K1pAyoI2rve-QbPhJGWiGYV4LodOsqKCW2dcblvWfBFi11VFicmTj2y1iwrXM-mNvOzac_X33qO1Su6Ck6VCJrEGj_33wNgGExY8bK9tpDyQTLwBJjvhfNOuT8eB5C7QLsx76tLT62Y_hCFl-Ok5CCIBBLMr8_balBLeMbJw'
    switch (method) {
        case "GET":
         

           
                return axios.get(
                url,
                { 
                    headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer '+token
                }}).then((res)=>{
                    //return response(res)
                    return res
                }).catch((err)=>{
                    return err?.response;
                });
        

            

        case "POST":
           
            return axios.post(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
        
        case "POSTMEDIA":

            return axios.post(url,body,configMedia()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });


           
        case "PUT":

            return axios.put(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
           
            
        case "DELETE":
            
       
            return axios.delete(url,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer '+token
                },
                data:body
            }).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

        case "PATCH":

            return axios.patch(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
      
          

        default:
            break;
    }
}

const downloadInvoice = async (service) => {
    const url = `${api_base}${service}`;
    let token = localStorage.getItem('token');
    const response = await axios.get(url, { 
        responseType: "blob",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const downloadPDF = async (service, id) => {
    try {
        const fileName = `factura_${id}.pdf`
        const data = await downloadInvoice(service);
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error("Error descargando el archivo:", error);
    }
};

